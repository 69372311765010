import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import db from './firebase.js';
import { debounce } from 'lodash';
import Toggle from 'react-toggle'
import { collection, addDoc, doc, getDoc, updateDoc, getDocs, query, deleteDoc} from "firebase/firestore"; 
import { GoogleAuthProvider, onAuthStateChanged, getAuth, signInWithPopup, signOut } from "firebase/auth";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const { Octicon, Octicons } = require('octicons-react')

const App = () => {
  const [sidebar, setSidebar] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [reveal, setReveal] = useState(false);
  const [user, setUser] = useState(null);
  const [notes, setNotes] = useState([]);
  // const [profilePicture, setProfilePicture] = useState(null);
  const [id, setId] = useState(null);
  const editorRef = useRef(null);
  const titleRef = useRef(null);
  // const user = null;

  const min_opacity = 0.05;
  const max_words = 10;

  
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  // console.log(auth.currentUser)

  // console.log(auth.currentUser)
  const photo = auth.currentUser ? auth.currentUser.photoURL : null

  const fill_transparent = () => {
    const editor = editorRef.current;
    const text = editor.innerText;
    const words = text.split(' ');
    editorRef.current.innerHTML = '';
    // console.log(reveal)
    words.forEach((word, index) => {
      const wordSpan = document.createElement('span');
      wordSpan.innerText = word + " ";

      let opacity = 0;
      if (index < words.length - max_words*2) {
        opacity = min_opacity
      } else if (index < words.length - max_words){
        opacity = min_opacity + (index - words.length + max_words*2)/(max_words);
      } else { opacity = 1; }
      if (reveal) {opacity = 1}
      
      // console.log(word, opacity);
      wordSpan.style.opacity = opacity
      if (index < words.length - 1) {
        const spaceSpan = document.createElement('span');
        spaceSpan.innerText = ' ';
        editor.appendChild(spaceSpan);
      }
      editor.appendChild(wordSpan);
    });
  }

  const move_cursor = () => {
    const editor = editorRef.current;
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(editor);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  const handleInput = () => {
    console.log("handleInput")
    fill_transparent();
    move_cursor();
    const cur_note = {
      title: titleRef.current.innerText,
      body: editorRef.current.innerText,
      id: id,
      last_saved: new Date()
    }
    debouncedSave(cur_note);
  };

  const handleInputTitle = () => {
    const cur_note = {
      title: titleRef.current.innerText,
      body: editorRef.current.innerText,
      id: id,
      last_saved: new Date()
    }
    debouncedSave(cur_note);
  };

  const handlePaste = (event) => {
    event.preventDefault(); // Prevent default paste behavior
    const pastedText = event.clipboardData.getData('text/plain');
    const words = pastedText.split(/\s+/); // Split pasted text into words
    const editor = editorRef.current;
    editorRef.current.innerHTML = '';
    console.log(reveal)
    words.forEach((word, index) => {
      const wordSpan = document.createElement('span');
      wordSpan.innerText = word + " ";

      let opacity = 0;
      if (index < words.length - max_words*2) {
        opacity = min_opacity
      } else if (index < words.length - max_words){
        opacity = min_opacity + (index - words.length + max_words*2)/(max_words);
      } else { opacity = 1; }
      if (reveal) {opacity = 1}
      
      // console.log(word, opacity);
      wordSpan.style.opacity = opacity
      if (index < words.length - 1) {
        const spaceSpan = document.createElement('span');
        spaceSpan.innerText = ' ';
        editor.appendChild(spaceSpan);
      }
      editor.appendChild(wordSpan);
      move_cursor();
      const cur_note = {
        title: titleRef.current.innerText,
        body: editorRef.current.innerText,
        id: id,
        last_saved: new Date()
      }
      debouncedSave(cur_note);
    });
  }

  const copyText = () => {
    const text = editorRef.current.innerText;
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  const toggleReveal = () => {
    setReveal((prevReveal) => !prevReveal);
  };

  useEffect(() => {
    handleInput();
    console.log("useEffect called, getNotes called")
    getNotes();
  }, [reveal]);

  const debouncedSave = debounce((cur_note) => {
    // console.log("DEBOUNCED SAVE CALLED WITH: ", cur_note)
    handleSave(cur_note);
    // console.log('Saving:', textToSave);
  }, 500);

  async function handleSave(cur_note) {
    // Save the title and body to Firebase
    let title = cur_note.title;
    let body = cur_note.body;
    var this_id = cur_note.id;
    // console.log("HANDLESAVE CALLED WITH: ", cur_note)
    if (title == '' || body == '' || (body == 'Start typing...' && title == 'New Note')) {
      return;
    }
    if (!auth.currentUser) {
      return;
    }
    const changes = cur_note;
    // console.log("UPDATED NOTE:", changes)
    try {
      if(this_id) {
        const docRef = doc(db, "users", auth.currentUser.uid, "notes", this_id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          if (docSnap.data().title == title && docSnap.data().body == body) {
            return
          } else {
            await updateDoc(docRef, changes);
            console.log("handleSave, document updated")
            getNotes();
          }
        }
      } else {
        const docRef = await addDoc(collection(db, "users", auth.currentUser.uid, "notes"), changes);
        console.log("Document written with ID: ", docRef.id);
        setId(docRef.id)
        console.log("handleSave, document added")
        getNotes();
      }} catch (e) {
        console.error("Error adding document: ", e);
      }
  };

  async function getNotes() {
    let newnotes = [];
    if (!auth.currentUser) { return;}
    const q = query(collection(db, "users", auth.currentUser.uid, "notes"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      newnotes.push({
        id: doc.id, 
        title: doc.data().title, 
        body: doc.data().body,
        last_saved: doc.data().last_saved
      })
    });
    //sort by last_save:
    newnotes.sort((a, b) => (a.last_saved > b.last_saved) ? -1 : 1)
    setNotes(newnotes)
    return newnotes
  }

  async function deleteNote() {
    if (!auth.currentUser) { return;}
    if(id) {
      await deleteDoc(doc(db, "users", auth.currentUser.uid, "notes", id));
      console.log("Document deleted with ID: ", id, ", getNotes called");
      let newsy = await getNotes()
      console.log("NEWSY: ", newsy)
      changeNote(newsy[0])
    }
  }

  async function logOut() {
    signOut(auth).then(() => {
      // Sign-out successful.
      console.log("signed out, getNotes called")
      getNotes();
      setUser(null);
    }).catch((error) => {
      // An error happened.
      console.log("error signing out")
    });
  }

  async function logIn() {
    console.log("LOGIN CALLED")
    if (!auth.currentUser) {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        console.log("CREDENTIAL:" ,credential)
        const token = credential.accessToken;
        const user = result.user;
        let newsy = getNotes()
        console.log("NEWSY: ", newsy)
        changeNote(newsy[0])

      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
    } else {
      getNotes();
    }
  }

  const newNote = () => {
    console.log("NEW NOTE")
    titleRef.current.innerText = 'New Note';
    editorRef.current.innerText = 'Start typing...';
    setId(null);
  }

  const changeNote = (note) => {
    titleRef.current.innerText = note.title;
    editorRef.current.innerText = note.body;
    setId(note.id)
    fill_transparent();
    move_cursor();
  }

  useEffect(() => {
    // Update the current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const timeSince = (date) => {
    // console.log("NOTE DATE: ", date.toDate(), ", NOW: ", currentTime)
    const seconds = Math.floor((currentTime - date.toDate()) / 1000);
    let interval = seconds / 31536000;
    if (interval > 1) {   return Math.floor(interval) + " years ago";}
    interval = seconds / 2592000;
    if (interval > 1) {   return Math.floor(interval) + " months ago";}
    interval = seconds / 86400;
    if (interval > 1) {   return Math.floor(interval) + " days ago";}
    interval = seconds / 3600;
    if (interval > 1) {   return Math.floor(interval) + " hrs ago";}
    interval = seconds / 60;
    if (interval > 1) {   return Math.floor(interval) + " mins ago";}
    return "now";
  }


  const HomeScreen = () => {
    return (
      <div className="App">
  
        <TransitionGroup>
          <CSSTransition
            key={sidebar ? 'sidebar-in' : 'sidebar-out'}
            classNames="sidebar"
            timeout={300}
          >
            { sidebar ?
            <div className="sidebar">
              <div className="chevron-wrapper">
                <div className="logo">petals.</div>
                <button 
                  className='button icon plus'
                  onClick={newNote}>
                    <Octicon icon={Octicons.plus} />
                </button>
                <button 
                  className='button icon chevron'
                  onClick={() => setSidebar(!sidebar)}>
                    <Octicon icon={Octicons.chevronLeft} />
                </button>
              </div>
              
              <div className="all-notes">
                {notes.map((note) => (
                  <div 
                  className={`note ${note.id === id ? 'selected' : ''}`}
                    key={note.id}
                    onClick={() => changeNote(note)}
                  >
                    <div className="all-notes-title">{note.title}</div>
                    <div className="last-saved">{note.last_saved ? timeSince(note.last_saved) : ""}</div>
                  </div>
                ))}
              </div>
            </div> 
            : 
            <div className="sidebar hidden">
              <button 
                className='button icon'
                onClick={() => setSidebar(!sidebar)}>
                  <Octicon icon={Octicons.chevronRight} />
              </button>
              <button 
                className='button icon'
                onClick={newNote}>
                  <Octicon icon={Octicons.plus} />
              </button>
            </div> 
            }
          </CSSTransition>
        </TransitionGroup>
  
        <div className="vertical">
          <div className="button-header">
            <div className='button-grp'>
              <Toggle
                id='toggle'
                checked={reveal}
                onChange={toggleReveal} 
                icons={false}
              />
              <label htmlFor='toggle'>
                <Octicon className="icon" icon={Octicons.eye} />
              </label>
  
            </div>
  
            <div className='button-grp'>
              <button 
                className='button delete'
                onClick={deleteNote}>
                  Delete Note
              </button>
              <button 
                className='button'
                // onClick={handleSave}
                onClick={() => {
                  const cur_note = {
                    title: titleRef.current.innerText,
                    body: editorRef.current.innerText,
                    id: id,
                    last_saved: new Date()
                  }
                  handleSave(cur_note);
                  // debouncedSave()
                }}
              >
                  Save
              </button>
              
  
              {
                auth.currentUser ?
                <div className="button-grp">
                  <img src={photo} width="0" height="0" borderradius="20" />
                  <img 
                    src={auth.currentUser ? auth.currentUser.photoURL : ""} 
                    alt="profile-pic" 
                    onClick={logOut}
                    className="profile-pic" 
                  />
                </div>
                 :
                <button 
                  className='button'
                  onClick={logIn}>
                    Log In
                </button>
  
              }
  
            </div>
          </div>
  
  
          <div className="note-space">
            <div
              contentEditable
              className="title"
              ref={titleRef}
              onInput={handleInputTitle}
              // set title
              placeholder={"title"}
            />
            <div
              ref={editorRef}
              contentEditable
              className="editor"
              onInput={handleInput}
              onPaste={handlePaste}
              placeholder={"type"}
            />
          </div>
        </div>
  
      </div>
    );
  };
  const LogInScreen = () => {
    return(
      <div className="App empty">
        <div className="title hug">welcome to petals.</div>
        <button
          className='button'
          onClick={logIn}>
            Log In
        </button>
        <div ref={titleRef} />
        <div ref={editorRef}/>
      </div>
    )
  }


  
  onAuthStateChanged(auth, (u) => {
    if (u) {
      console.log("user detected")
      // getNotes();
      if (user === null) {
        console.log("Setting user, getting notes")
        setUser(u);
        // console.log("Getting notes")
        getNotes();
      }
    
    } 
    else {
      // User is signed out
      console.log("no user")
    }
  });

  return (
    <div>
      {user ? <HomeScreen /> : <LogInScreen />}
      {/* <HomeScreen /> */}
    </div>
  )

  
};

export default App;
